import styled from 'styled-components/macro';
import { Box, Heading } from '~styled';

const Customerstory = styled(Box)`
background-color: #FFFFFF;
padding-top: 50px;
padding-bottom: 50px;

.bd{
  @media (min-width: 480px){
    
  }
  
}
@media (min-width: 768px){
  padding-top: 30px;
}

@media (min-width: 992px){
  padding-top: 50px;
}
`
Customerstory.Title = styled(Heading)`
font-size: 35px;
font-weight: 500;
@media (min-width: 480px) {
  font-size: 38px;
}

@media (min-width: 768px) {
  font-size: 48px;
}

@media (min-width: 992px){
  font-size: 50px;
}
@media (min-width: 1200px){
  font-size: 52px;
}
`
export default Customerstory;