import React from "react";

const Card = ({ options }) => {
  return (
    <div className="card-box ">
      <div className=" mb-8 ">
        <img src={options.image} alt="" className="card-image" style={{display : 'inline-block'}}/>
        <h4 className="card-name" >{options.userlink}</h4>
        <span className="card-position">{options.position}</span>
      </div>
      <div className="">
      <h1 className="card-title"> {options.title} </h1>
        <p className="card-content">
          {options.content}
        </p>
        
      </div>
    </div>
  );
};

export default Card;
